
.errorclass{
  position: fixed;
  color: red;
  font-size: 15px;
  text-align: left;
  
   width: 100% 
}

.otpbtn{
  color: white;
  background-color: #64bcec;
  border: none;
  font-size: 15px;
  padding: 6px 0px;
  margin-top: 0px;
  width: 400px;
 
  text-align: center;
}

.otp{
  text-align: center;
  margin: 15px px;
  color: black;
  font-weight: 600;
}
.inputOtp{
display: flex;
margin-top: 15px;
justify-content: center;
}
.pass{
  
  width: 45px;
  margin: 8px 10px;
  text-align: center;

}
.email-text{
  font-weight: 300;
  font-size: medium;}

.background-img {
  background-image: url('./images/Login.png');
  position: relative;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.login-row {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}


.loginPageOuterDiv {
  width: 90%;
  margin-left: 10px;
  margin-top: 6rem;
}

.pwds-container {
  position: relative;
  padding: 1.3rem 0 0;
}

.pwds-container img {
  cursor: pointer;
  position: absolute;
  width: 28px;
  right: 10px;
  top: 2rem;
}

.fieldspace {
  padding: 1.3rem 0;
}

.text {
  background: "white"
}


.size {
  font-size: 35px;
}


img.pb-1.logo {
  width: 20rem;
  margin: auto;
}


.MuiFormControlLabel-root {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-left: -25px !important;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

.MuiGrid-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  text-align: right;
}

.link-text {
  font-size: 1.4rem;
  color: black;
  font-weight: 100;
}

.link-text:hover {
  color: black;
}

span.MuiButton-label {
  font-size: 13px;
}

.bucket {
  margin-top: 50%;
  grid-column: 2/4;
}

a.MuiTypography-root.MuiLink-root.MuiLink-underlineHover.link-text.MuiTypography-body2.MuiTypography-colorPrimary {
  color: black;
}


.RememberMe {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  padding-bottom: 12px;
}

.remember {
  grid-column: 1/2;
  align-items: center;
  display: grid;
  grid-template-columns: 2rem 10rem;
}

.RememberText {
  margin-left: -20px !important;
}

.formclass {
  padding: 1rem 1rem;
}

.remeberBox {
  margin-top: 0px !important;
  align-self: center;
  justify-self: center;
}

.rememberLabel {
  color: black;
  font-size: 1.4rem;
  font-weight: 100;
  padding-left: 5px;
  justify-self: baseline;
  margin-bottom: 0px;
}

.login-input {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid black;
  font-size: 17px;
  font-family:inherit;
}

.forgot {
  justify-self: end;
}

@media screen and (min-width:769px) and (max-width:1024px) {
  .login-row {
    grid-template-columns: repeat(8, 1fr);
  }

  .bucket {
    margin-top: 40%;
    grid-column: 2/5;
  }
  .errorclass{

  }
}

@media screen and (min-width:481px) and (max-width:768px) {
  .background-img {
    background-image: url('./images/backgroundTab (1).png');
    position: relative;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    overflow-x: overlay;
    background-size: 100%;
  }
  .login-row {
    grid-template-columns: repeat(5, 1fr);
  }

  .bucket {
    margin-top: 90%;
    grid-column: 2/5;
  }
  .login-input{
    padding: 3px;
  }
  img.pb-1.logo {
    width: 18rem;
  }
  .pwds-container img{
    top: 1.6rem;
  }
  .errorclass{
width: 250px;
    text-align: left;
  }
}

@media screen and (min-width:320px) and (max-width:481px) {
  .background-img {
    background-image: url('./images/backgorundmobile.png');
    position: relative;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    overflow-x: overlay;
    background-size: 100%;
  }
  .login-row {
    grid-template-columns: repeat(6, 1fr);
  }
  .bucket {
    margin-top: 25rem;
    grid-column: 1/-1;
  }
  img.pb-1.logo {
    width: 18rem;
  }
  .rememberLabel {
    font-size: 1.2rem;
  }
  .link-text {
    font-size: 1.2rem;
  }
  .login-input {
    padding: 2px;
  }
  .pwds-container img{
    width: 25px;
    right: 6px;
    top: 1.7rem;
  }
  .errorclass{
    margin-left: 157px
  }
  
}