@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

* {
  box-sizing: border-box;
}

body {
  min-height: 100%;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
  background-color: #f8f9fa !important;
  background-size: auto;
}

.login {
  width: 100%;
  height: 100vh;
}

.forgot-outer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.login-1 {
  grid-column: 2/4;
  margin-top: 18rem;
  padding: 0 3rem;
}

img {
  width: 190px;
}

a {
  text-decoration: none;
  background-color: transparent;
  color: black;
  font-weight: 500;
}

a:link {
  text-decoration: none;
}

a:hover {
  color: black;
}

/* input {
  border-radius: 10px;
} */

h2,
h4,
h5 {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
  text-align: center;
  width: 600px;
  font-size: 30px;
  margin: 10px 0;
}

h6 {
  font-size: 20px;
  margin: 25px;
  font-weight: 700;
}

body,
html,
.custom-control-label {
  font-weight: 400;
}

.rem {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}

.specs {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 25%;
  text-align: center;
  font-size: 20px;
  outline: none;
  border: 1px solid rgb(192, 191, 191);
}

.form-group {
  width: 375px;
}

.admin {
  display: flex;
  flex-direction: column;
  margin: 50px;
  padding: 50px;
  background-color: #fff;
  border-radius: 15px;
}

.admin1 {
  color: #000 !important;
}

p {
  text-align: center;
  font-size: 20px;
}

.modal-content {
  border-radius: 20px !important;
  margin: 120px;
}

th {
  overflow-x: auto;
}

button {
  box-shadow: none !important;
  font-weight: 500 !important;
  border-radius: 10px !important;
  outline-style: none !important;
}

.modal-body {
  margin: 30px !important;
}

label {
  font-weight: 500;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: #64bcec !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: grey !important;
}

.MuiAppBar-colorDefault {
  background-color: #fff !important;
  box-shadow: none !important;
}

.PrivateTabIndicator-colorSecondary-3 {
  background-color: #64bcec !important;
  height: 5px !important;
}

.MuiTab-wrapper {
  text-align: left !important;
  align-items: flex-start !important;
}

.PrivateTabIndicator-root-1 {
  width: 50px !important;
  margin-left: 12px !important;
}

.MuiTableCell-root {
  padding-right: 30px !important;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  font-size: 15px !important;
}

.client {
  margin: 70px;
  display: grid;
  grid-template-columns: 50% 25% 25%;
  grid-gap: 50px;
}

.search {
  padding: 13px !important;
  width: 100%;
  outline: none !important;
}

.MuiOutlinedInput-input {
  padding: 15px !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #64bcec !important;
}

.MuiList-padding {
  padding: 0 !important;
}

.MuiSelect-selectMenu:focus {
  background-color: #fff !important;
}

.MuiSelect-selectMenu {
  background-color: #fff !important;
}

.MuiSelect-icon {
  color: #64bcec !important;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}

.MuiSelect-outlined.MuiSelect-outlined {
  outline: none !important;
  border: none !important;
}

#dropdown {
  color: black;
  background-color: transparent;
  border: none;
  font-size: 25px;
}

.dropdown-menu.show {
  width: 100%;
  border-radius: 5px;
  margin: 0px;
}

.nimap-logo {
  width: 21rem;
  height: 10rem;
  justify-self: center;
}

.forgotForm {
  justify-self: center;
  display: grid;
  gap: 1rem;
}

/* 
.inputcol {}

.btnCol {} */

.projectName {
  font-size: 30px;
  color: #64bcec;
  margin-top: 5px;
}

.formTitle {
  font-size: 25px;
}

.forgot-input {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid black;
  font-size: 17px;
  font-family:inherit;
}

.MuiInputBase-input{
font-size: 17px !important;
}
.submit-btn {
  color: white;
  background-color: #64bcec;
  width: 100%;
  border: none;
  font-size: 15px;
  padding: 6px 0px;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .nimap-logo {
    width: 18rem;
    height: 8rem;
  }

  .login-1 {
    padding: 0rem;
  }

  .projectName {
    font-size: 26px;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .formTitle {
    font-size: 22px;
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .login-1 {
    grid-column: 2/5;
    margin-top: 38rem;
  }

  .forgot-outer {
    grid-template-columns: repeat(5, 1fr);
  }

  .formTitle {
    font-size: 20px;
    margin-bottom: 0px;
  }

  .projectName {
    font-size: 22px;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .nimap-logo {
    width: 16rem;
    height: 6rem;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .formTitle {
    font-size: 20px;
    margin-bottom: 0px;
  }

  .projectName {
    font-size: 22px;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .nimap-logo {
    width: 16rem;
    height: 6rem;
  }

  .login-1 {
    grid-column: 2/6;
    margin-top: 30rem;
  }
}
