.main-div{
  padding-top:22%;
}
.pleasewaittext{
    font-size: 25px;
    color: gray;
    font-family: 'Sora', sans-serif;
    text-align: center;
}
.loaderimg{
    height: 100px;
    width: 100px;
}