.form_row {
  margin-top: 3rem;
}

.downviewicon {
  text-align: center;
}

.icons {
  display: flex;
}

.modal-title {
  font-size: 23px !important;
  text-align: left !important;
}
.adduserBox {
  width: 750px;
}

.invoiceBox {
  width: 800px;
}

.viewBox {
  width: 590px;
}

.modalViewBox{
  width: calc(100% - 50px);
  max-height: 500px;
  overflow-y: scroll;
}

.rejctBox {
  width: 345px;
}

.delBox {
  width: 280px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0px solid #000;
  background-color: white;
  box-shadow: 10;
  border-radius: 20px;
  padding: 30px;
}
.deletebox {
  width: 280px;
}

.label-name {
  font-size: 16px;
  font-weight: normal;
}

.inputborder {
  width: 47%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #b7c4c9;
  margin-top: 7px;
}

.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.input-borders::after {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  height: 95px;
  overflow: scroll;
}

.selectInput {
  width: 100%;
}

.input-area {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #b7c4c9;
  overflow-y: scroll;
  margin-top: 7px;
  height: 50px;
  min-height: 50px !important;
  resize: vertical;
}

.add-btns {
  margin-right: 5%;
  background-color: #0bb1d3;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  padding: 0 30px;
  font-size: 20px;
}

.add-btns20 {
  margin-right: 5%;
  background-color: #0bb1d3;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  padding: 0 30px;
  font-size: 20px;
}

.cancel-btns {
  margin-right: 5%;
  background-color: #9fa4a5;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  padding: 0 30px;
  font-size: 20px;
}

.cancel-btns20 {
  margin-right: 5%;
  background-color: #9fa4a5;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  padding: 0 30px;
  font-size: 20px;
}

.form-label {
  text-align: left;
}

.addRole {
  margin-right: 5%;
  background-color: #0bb1d3;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 90%;
}

.addCncl {
  margin-right: 5%;
  background-color: #9fa4a5;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 90%;
}

.addRole {
  margin-right: 5%;
  background-color: #0bb1d3;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 90%;
}

.addCncl {
  margin-right: 5%;
  background-color: #9fa4a5;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 90%;
}

.del-logo {
  width: 50px;
  height: 50px;
  margin-left: 8.5rem;
}

.del-title {
  text-align: center;
  font-size: 1rem;
}

.del-hr {
  margin-left: 86px;
}

.del_row {
  margin-top: 1.9rem;
}

.delete-message {
  text-align: center;
  margin: 1rem !important;
  font-size: 20px !important;
}

.del-add {
  margin-right: -3%;
  background-color: #0bb1d3;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 100%;
  font-size: 20px;
}

.del-cncl {
  background-color: #9fa4a5;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 100%;
  font-size: 20px;
}

.viewCncl-btn {
  background-color: #0bb1d3;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 40%;
  font-size: 18px;
}

.viewRow {
  margin-top: 1.8rem;
}

.error {
  font-size: 1rem;
  color: rgb(238, 73, 73);
}

.invView {
  width: 48%;
  cursor: pointer;
}

.modalclass {
  position: absolute;
  top: 27rem;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0px solid #000;
  background-color: white;
  box-shadow: 10;
  border-radius: 20px;
  padding: 30px;
}

.modalclass30{
  position: absolute;
  top: 27rem;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0px solid #000;
  background-color: white;
  box-shadow: 10;
  border-radius: 20px;
  padding: 5px;
}

.modalclass1 {
  position: absolute;
  top: 33rem;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0px solid #000;
  background-color: white;
  box-shadow: 10;
  border-radius: 20px;
  padding: 30px;
}

.input-field {
  width: 100%;
  border: 2px solid black;
}

.modalbox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 5px;
  padding: 25px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 95%;
}
.ppendingbox {
  width: 550px;
}

.closeIcn {
  text-align: end;
}

.closeCurs {
  cursor: pointer;
}

.modaltd {
  min-width: 0px !important;
}

.countmodal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 25px;
  padding: 25px;
  overflow-x: hidden;
  overflow-y: auto;
}

.changeModal {
  position: absolute;
  top: 30rem;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0px solid #000;
  background-color: white;
  box-shadow: 10;
  border-radius: 20px;
  width: 319px;
  padding: 20px;
}

.personalInfo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0px solid #000;
  background-color: white;
  box-shadow: 10;
  width: 600px;
  padding: 15px 40px !important;
  border-radius: 20px;
}

.personalModal {
  position: absolute;
  top: 20%;
  left: 29%;
  width: 573px;
}

.files {
  width: 100%;
  margin-top: 7px;
  border-radius: 20px !important;
  box-shadow: 2px 2px 1px lightgray;
  border: 1px solid #b7c4c9;
}

::-webkit-file-upload-button {
  border: none;
  background-color: #d9d9f1;
  border-radius: 20px;
  height: 35px;
  cursor: pointer;
}

.input-borders.row {
  margin-left: 0rem;
}

.rejectmsg {
  border-radius: 5px;
  border: 1px solid #b7c4c9;
  margin-top: 0px;
  width: 100%;
  resize: none;
}

.addBtn {
  background-color: #0bb1d3;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  margin-left: 14rem;
  margin-top: 5px;
  padding: 0 20px 0 20px;
}

.addReject {
  background-color: #0bb1d3;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 100%;
  margin-top: 5px;
  padding: 0 20px 0 20px;
  font-size: 18px;
}

.cnclReject {
  background-color: #9fa4a5;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 100%;
  margin-top: 5px;
  font-size: 18px;
  padding: 0 20px 0 20px;
}

.cnclBtn {
  background-color: #9fa4a5;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  margin-left: 40px;
  margin-top: 5px;
  padding: 0 20px 0 20px;
}

.files input {
  opacity: 0;
  z-index: 99;
  display: block;
  cursor: pointer;
}

.symbol {
  width: 3.5rem;
}

.downloadIcon {
  height: 25px !important;
  width: 25px !important;
  color: #4bcbf0;
  cursor: pointer;
}

.view {
  height: 25px !important;
  width: 25px !important;
  color: #4bcbf0;
  cursor: pointer;
}

b.invoice {
  margin-left: -500px;
}

b.invoice1 {
  margin-left: -533px;
  font-size: 1.9rem;
}

.error-para {
  color: red;
  font-size: small;
  position: fixed;
}

.mandatory {
  color: red;
}

.AddBtn {
  margin-left: 144px;
  background-color: #0bb1d3 !important;
  font-size: 18px;
  margin-left: 24px;
}

.CancelBtn {
  margin-left: 10px;
  background-color: #0bb1d3 !important;
  font-size: 18px;
}

label.form-label {
  font-size: 16px;
  text-align: left !important;
  margin-top: 12px;
}

.pwd-container {
  position: relative;
}

.pwd-container input {
  width: 100%;
  font-size: 17px;
  padding-left: 8px;
  font-family: inherit;
}

.pwd-container img {
  cursor: pointer;
  position: relative;
  width: 26px;
  right: 8px;
  top: -1px;
}

.personalCncl {
  background-color: #0bb1d3;
  border: none;
  padding: 7px 22px;
  margin-left: 198px;
  margin-top: 15px;
  color: white;
}

.tabbox {
  font-size: large !important;
}

.select-react {
  padding-top: 7px;
}

.documents {
  color: #445ff7;
  text-decoration: underline;
  cursor: pointer;
}

.error-msg {
  color: red;
  font-size: 14px;
  margin-top: -11px;
}

.change_row.row {
  padding-left: 20px;
}

.topviewrow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.personalInfo {
  padding: 2% 4%;
}

.h3,
h3 {
  font-size: 24px;
  margin-left: -331px;
}

.label-name12 {
  margin-left: -12px;
  font-size: 18px;
}

input#oldpassword {
  margin-bottom: 16px;
  height: 40px;
}

input#newpass {
  margin-bottom: 16px;
  height: 40px;
}

input#confirmpass {
  height: 40px;
}

.viewName {
  font-weight: 600;
}

.lastrow {
  margin-bottom: 1rem;
}

.supprottxt {
  font-weight: 600;
}

.adminVenBox {
  width: 700px;
  height: 70vh;
  overflow-y: auto;
}
.adminVenBox1{
  width: 700px;
  height: auto;
  overflow-y: auto;
}

.add-vendor-error-text {
  color: red;
  font-size: 14px;
}

.add-vendor-modal {
  max-height: 60vh;
  overflow-y: auto;
}


@media screen and (min-width: 1025px) and (max-width: 1200px) {
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .modal-title {
    font-size: 20px !important;
    text-align: left !important;
  }
  .viewBox {
    width: 408px;
    height: 443px;
    overflow-y: auto;
  }
  .modalclass {
    padding: 25px;
  }
  .modalclass1 {
    padding: 25px;
  }
  .viewRow {
    margin-top: 0.5rem;
  }
  .adminVenBox {
    width: 600px;
    height: 70vh;
    overflow-x: overlay;
  }
  .adduserBox {
    width: 600px;
  }
  button.AddBtn.btn.btn-primary.btn-large {
    margin-left: 24px !important;
  }
  .label-name12 {
    margin-left: -15px;
    font-size: 18px;
  }
  .AddBtn {
    font-size: 18px;
  }
  .CancelBtn {
    font-size: 18px;
  }
  .changeModal {
    width: 300px;
    padding: 15px;
    height: 360px;
  }
  input#oldpassword {
    margin-bottom: 10px;
    height: 35px;
  }
  input#newpass {
    margin-bottom: 10px;
    height: 35px;
  }
  input#confirmpass {
    height: 35px;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .modalclass {
    padding: 20px;
  }

  .modalclass1 {
    padding: 20px;
  }

  .input-borders {
    padding: 6px;
  }

  .viewName {
    font-size: 17px;
  }

  .viewBox {
    width: 350px;
    height: 360px;
    overflow-x: overlay;
  }

  .labelname {
    font-size: 17px;
  }

  .supprottxt {
    font-size: 17px;
  }

  .viewRow {
    margin-top: 0rem;
  }
  .adminVenBox {
    width: 400px;
    overflow-x: overlay;
  }
  .adduserBox {
    width: 400px;
  }

  button.AddBtn.btn.btn-primary.btn-large {
    margin-left: 24px !important;
  }
  .label-name12 {
    margin-left: -15px;
    font-size: 16px;
  }
  .AddBtn {
    font-size: 16px;
  }
  .CancelBtn {
    font-size: 16px;
  }
  .changeModal {
    width: 300px;
    padding: 15px;
    height: 360px;
  }
  input#oldpassword {
    margin-bottom: 10px;
    height: 35px;
  }
  input#newpass {
    margin-bottom: 10px;
    height: 35px;
  }
  input#confirmpass {
    height: 35px;
  }
  .modal-title {
    font-size: 21px !important;
  }
  .deletebox {
    width: 250px;
  }
  .delete-message {
    margin: 0.7rem !important;
    font-size: 16px !important;
  }
  .del_row {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 320px) and (max-width: 481px) {
  .viewBox {
    width: 289px;
    height: 356px;
    overflow-y: auto;
  }

  .adduserBox {
    width: 300px;
  }

  .labelname {
    font-size: 16px;
  }

  .supprottxt {
    font-weight: 600;
    font-size: 16px;
  }

  .cnclCols {
    margin-top: -35px;
    margin-left: 145px;
  }

  .invoiceBox {
    width: 360px;
    height: 420px;
  }

  .rejctBox {
    height: 164px;
    width: 277px;
  }

  .rejectbtn.row {
    display: flex;
    flex-wrap: nowrap;
  }

  .modalclass {
    padding: 15px;
  }
  .modalclass1 {
    padding: 15px;
  }

  .cancel-btns {
    margin-right: 0%;
    height: 36px;
    font-size: 17px;
  }

  .add-btns {
    margin-right: 56%;
    height: 35px;
    padding: 0 40px;
    font-size: 17px;
    margin-top: 9px;
  }

  .label-name {
    font-size: 16px;
    font-weight: normal;
    margin-top: 6px;
  }

  .modal-title {
    font-size: 19px !important;
  }

  .form_row {
    margin-top: 8px;
  }
  .downloadIcon {
    width: 3rem !important;
  }
  .view {
    width: 3rem !important;
  }
  .viewName {
    font-weight: 600;
    font-size: 16px;
  }

  .viewRow {
    margin-top: 0rem;
  }

  button.AddBtn.btn.btn-primary.btn-large {
    margin-left: 24px !important;
  }
  .label-name12 {
    margin-left: -15px;
    font-size: 16px;
  }
  .AddBtn {
    font-size: 16px;
  }
  .CancelBtn {
    font-size: 16px;
  }
  .changeModal {
    width: 280px;
    padding: 15px;
    height: 343px;
  }
  input#oldpassword {
    margin-bottom: 10px;
    height: 30px;
  }
  input#newpass {
    margin-bottom: 10px;
    height: 30px;
  }
  input#confirmpass {
    height: 30px;
  }
  .pwd-container input {
    font-size: 18px;
  }
  .personalInfo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0px solid #000;
    background-color: white;
    box-shadow: 10;
    width: 349px;
    padding: 20px;
    height: 485px;
  }

  .personalCncl {
    background-color: #0bb1d3;
    border: none;
    padding: 7px 22px;
    margin-left: 96px;
    margin-top: 15px;
    color: white;
  }
  .adminVenBox {
    width: 320px;
    overflow-x: overlay;
  }
  .delete-message {
    font-size: 16px !important;
    margin: 0.5rem !important;
  }
  .del-hr {
    margin-left: 75px;
  }
  .del-logo {
    margin-left: 7rem;
  }
  .deletebox {
    width: 224px;
  }
  .del_row {
    margin-top: 0.9rem;
  }
  .del-add {
    font-size: 18px;
  }
  .del-cncl {
    font-size: 18px;
  }
  .ppendingbox {
    width: 300px;
  }
}

.tannumcol{
  margin-bottom: 10px !important;
}
.tannumlabel{
  margin-bottom: 10px !important;
}

.addbank-input-div{
  height: 40px
}

.bankmodaldropdown{
  height: 35px;
  width: 300px;
  border-radius: 5px;
  outline-color:black!important; 
  border-color: #D1DADD;
}
.inp-bankname{
  border-radius: 5px;
  border: 1px solid #b7c4c9;
}
.csvfile-input{
  box-shadow: 0px 0px 2px #555;
  border-radius: 5px;
  width: 300px;
  outline-color:black!important; 
  border-color: #D1DADD;
}
.csvfile-input::file-selector-button{
  border-radius: 5px;

}
.flexrowaddbank{
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.lblbank{
  height:42px;
  display:flex;
  align-items: center;
}
.admindoc{
  height: 50px !important;
  min-height: 50px !important;
 overflow-y: scroll;
 margin-top:10px;
}

.inconsdiv{
  display: flex;
  align-items: center;
  gap: 0px 2px;
}

.edit-vendor-form-container {
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 10px;
}