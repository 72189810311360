.table  {
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
  
}

.tableC-financePaidModal{
  border-radius: inherit;
  max-height: 150px;
  overflow: scroll;
  padding: 20px;
}

.table-financePaidModal{
  width: 100%;
}
.tableHead {
  display: table-header-group;
}

.tableRow {
  display: table-row-group;
  margin: 20px 0;
}

.admintitle {
  font-size: 22px;
}

.userfilter-btns {
  text-align: end;
  padding-right: 20px;
}

.disableClick {
  cursor: default;
}

/* modal design */

.date-input {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  padding: 0.7rem;
  border-radius: 5px;
  border: 1px solid #a2afb4;
}

.filterBox {
  position: absolute;
  top: 69rem;
  left: 167rem;
  transform: translate(-50rem, -50rem);
  width: 300px;
  height: 200px;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
}

/*------------------------*/
.select-box {
  margin-top: 10px;
  border: 1px solid #a2afb4;
  border-radius: 10px;
  width: 100%;
  padding: 1rem;
  outline: none;
}

.modal-btn {
  margin-top: 20px;
  margin-bottom: 30px;
  background-color: #0dcaf0;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 100%;
}

/* @media screen and (max-width: 600px) {
} */

.filter-pop {
  padding: 20px;
}

.popBox {
  border: 1px solid white;
  padding: 20px;
}

.table-border {
  border: 1px solid rgba(13, 202, 240, 0.3);
  border-radius: 1.5rem;
  padding-left: 32px;
  padding-right: 32px;
}

.space {
  margin-top: 2%;
}

.statusdata {
  margin: 25px 0;
}
.countdata {
  margin: 25px 0;
}

.makeStyles-drawerPaper-6 {
  width: 15%;
  overflow-x: clip;
}

.makeStyles-root-5 {
  display: flex;
  z-index: 0;
  padding-top: 24px;
}

.isDisabled {
  pointer-events: none;
  opacity: 0.8;
}

.enabled {
  color: #0dcaf0 !important;
  cursor: pointer;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  font-size: 22px;
  color: black;
  background-color: inherit;
  border-bottom: 4px solid #0dcaf0;
  opacity: 1;
}

.nav-pills .nav-link {
  font-size: 22px;
  border-radius: 0.25rem;
  color: black;
  opacity: 0.4;
}

/* .appracp{
    border: 2px solid red;
    margin-left: 20%;
    
}

.apprrej{
    border: 2px solid red;
    margin-left: 319%;
} */

/* .table-link vendorview{
    font-size: 50px!important;
} */

span.table-link.vendorview {
  font-size: 18px;
}

span.financeUser {
  color: black;
  font-size: 17px;
}

.vendorList {
  color: black;
  font-size: 18px;
}

.vendorUserLi {
  color: black;
  font-size: 18px;
}

/* .MuiBox-root-125 {
    height: 80%;
} */

.counts {
  cursor: pointer;
}

.permission-row {
  margin: 20px 0 !important;
  display: grid;
  border: 1px solid #0dcaf0;
  border-radius: 10px;
  padding: 10px;
}

.role-title {
  align-self: start;
  justify-items: start;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 100px;
  align-items: center;
  align-content: center;
  padding: 1rem 0;
}

.switch-block {
  display: grid;
}

.permit {
  grid-template-columns: repeat(5, 1fr);
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0.5em;
  grid-row-gap: 0.6em;
  justify-items: start;
  grid-auto-columns: auto;
}

.permit-label {
  font-size: 15px;
  margin-left: 5px;
}

.entity-title {
  font-weight: bold;
}

.admin-label {
  padding-left: 5px;
}

.permission-div {
  margin-right: 50px;
}

.permit-save-row {
  display: grid;
  align-items: center;
  justify-items: center;
}

.permit-btn {
  background-color: #0bb1d3;
  color: white;
  border: none;
  height: 3.5rem;
  width: 10rem;
  font-size: 20px;
}

.roles-row {
  margin: 17px 0px !important;
}

span > input {
  height: 0.7em;
  width: 0.8em;
}

.dropdownbox {
  border-radius: 5px;
  width: 20%;
}

.role-btns {
  width: 10%;
  background: #0dcaf0;
  border: none;
  color: white;
  height: 30px;
}

.datecurve{
  border-radius: 20px;
  border: 1px solid #dfeae6;
  padding: 0.8rem 1.2rem;
  color: black;
  width: 31%;
  outline: none;
  font-size: 18px;
  height: 40px;
}

.back-styles {
  display: flex;
  align-items: center;
  gap: 10px;
}

.arrow-back-icon {
  font-size: 20px !important;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .permit {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .statusdata {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .permit {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

@media screen and (min-width: 320px) and (max-width: 481px) {
  .statusdata {
    display: grid;
  }

  .admintitle {
    font-size: 20px;
  }

  span.table-link.vendorview {
    font-size: 14px;
  }

  .nav-pills .nav-link {
    font-size: 18px;
    padding: 0.2rem 0.6rem;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    font-size: 18px;
    padding: 0.2rem 0.6rem;
  }
  .permit {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: none;
  }
  .countdata {
    display: grid;
  }
}

.archiveHeader {
  display: flex;
  justify-content: space-between;
}

.toolTip{
  font-size: 16px !important;
  padding: 5px;
  word-wrap: break-word;
}
.tooltip-desc{
  height: 170px !important;
overflow-y: hidden;
border-radius:5px;
font-size: 16px !important;
}

.tooltip-desc{
  overflow-y: scroll; /* Add the ability to scroll */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.tooltip-desc::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.tooltip-desc{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.text-totalamount{
  color: black;
}
.tannumalign{
 text-align: left;
 padding-left: 15px !important;
}
.space1{
padding-left: 5px !important;

}
.red{
  color:red !important;
}

.btnparttext{
display:flex !important;
justify-content:space-between
}
.closeinpartialPayModal{
  height:30px;
  /* width: 10px; */
}


.btnproceed{
    background-color: #0dcaf0;
    color:white !important;
    outline:none;
   margin-left:9px;
   width:100px;
   border-radius: 5px !important;
}
.btnproceed:hover{
    opacity: 0.7;
}
.proceeinvoiceBtn{
    text-align: right;
}

.paramount{
  padding-left: 12px !important;
}
.fullamount{
  padding-left: 12px !important;
}
.descfinancerInv{
  padding-left: 12px !important;
}
.statusfinancerInv{
  padding-left: 12px !important;
}
.totalamountFinInv{
  padding-left: 12px !important;
}
.tdsamountFinInv{
  padding-left: 12px !important;
}
.gstamtFinInv{
  padding-left: 12px !important;
}
.dateFinInv{
  padding-left: 11px !important;
}
.downloadcsv{
  height:30px;
  width:30px;
}
.disablerejectpay{
  color:#B3E7FE;
}
.error-bankname{
  color: red;
  font-size: 14px;
}