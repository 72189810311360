.classes .form {
    background: white;
}

.nimaplogo {
    height: auto;
    width: 180px;
    padding-top: 7px;
    margin-left: -10px;
}

.text {
    background: "white"
}

.testLink {
    border-right: 5px solid #00a8ff;
    border-radius: 5px;
    color: black;
    font-weight: bold;
}

.arrowbtn {
    margin-left: -10px;
}

.input-border {
    border-radius: 0.4rem !important;
}

.size {
    font-size: 20px;
}

.test:focus {
    font-size: 35px !important;
    color: #f7fcff !important;
    font-weight: bold;
}

.side-list .MuiTypography-body1 {
    font-weight: bold;
    font-size: 15px;
}

.MuiTypography-displayBlock {
    display: block;
    font-size: 18px !important;
}

.MuiDrawer-paperAnchorDockedLeft {
    border-right: 0px solid rgba(0, 0, 0, 0.12);
    background-color: #f7fcff !important;
}

.side-menu {
    color: #ccebf1;
    margin-right: -22px;
    width: 25px;
    height: 25px;
}

.side-menulist {
    color: #ccebf1;
    margin-left: -4px;
    width: 35px;
    height: 30px;
}

.side-menus {
    color: #ccebf1;
    margin-left: -37px;
    width: 35px;
    height: 30px;
}

.listIcon {
    padding-left: 6rem;
}

.menu-item {
    background-color: #0dcaf0;
    height: 100% !important;
    text-align: left,
}

.toolbarTest {
    min-height: 204px !important;
}

.sidebar-name:hover {
    color: black !important;
    text-decoration: none;
}

.disableTab {
    cursor: not-allowed
}

.enableTab {
    pointer-events: all;
}

.sidebar-name {
    width: 239px !important;
    font-size: 25px !important;
}

.MuiPaper-root.MuiDrawer-paper.jss6.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.MuiPaper-elevation0 {
    border: none;
}

.makeStyles-drawerPaper-6 {
    width: 16.35% !important;
    border: hidden !important;
}

img {
    vertical-align: middle;
    margin-left: -27px;
    width: 180px;
}

.arrow {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.main-transition {
    transition: 5s !important;
}

.side-list-finance-text {
    font-size: 18px;
}

.expanded-submenu {
    display: block;
    width: 175px;
    font-size: 17px;
    margin-top: -10px;
    text-align: left;
    margin-left: 45px;
    color: #000;
}
.financer-submenu{
    margin-top: -17px;
}
.expand-icon {
    margin-left: -30px;
}

.finance-submenu-header {
    margin-top: -7px;
}
.navMain{
    padding: 0px !important;
  } 
@media screen and (min-width: 600px) and (max-width: 1279.95px) {
    img {
        width: 148px;
    }
    .nimaplogo {
        height: auto;
        width: 140px;
        padding-top: 0px;
        margin-left: -12px;
    }
    .MuiTypography-displayBlock {
        font-size: 17px !important;
    }
    .side-menus {
        width: 32px;
        height: 28px;
    }
    .sidebar-name {
        width: 219px !important;
    }
    .MuiListItem-root {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }
}

@media screen and (min-width: 0px) and (max-width: 599.95px) {
    .MuiTypography-displayBlock {
        font-size: 16px !important;
    }
    .side-menus {
        width: 27px;
        height: 21px;
    }
    .MuiListItem-root {
        padding-top: 1px !important;
        padding-bottom: 1px !important;
    }
    .sidebar-name {
        width: 192px !important;
    }
    .nimaplogo {
        height: auto;
        width: 140px;
        padding-top: 0px;
    }
    img {
        width: 144px;
    }
    .side-menulist {
        width: 30px;
        height: 27px;
    }
}

@media screen and (min-width:320px) and (max-width:481px) {
    .listIcon {
        padding-left: 5rem;
    }

    .sideopen {
        transform: translateX(-240px);
        visibility: hidden;
        transition-property: width;
        transition-duration: 2s;
        transition-timing-function: linear;
        transition-delay: 1s;
    }

    .sideclose {
        transform: 0px;
        transition-property: width;
        transition-duration: 2s;
        transition-timing-function: linear;
        transition-delay: 1s;
    }
}

.admin-notification{
height:20px !important;
width:20px !important;
border-radius: 5px !important;
background-color: #0DCAF0;
color:#000;
display: flex;
align-items: center;
justify-content: center;
align-content: center;
font-size: 17px !important;
margin-right: 5px!important;
padding-top: 1px !important;
}

.approver-notification{
    height:20px !important;
    width:20px !important;
    border-radius: 5px !important;
    background-color: #0DCAF0;
    color:#000;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    font-size: 17px !important;
    margin-right: 5px!important;
    padding-top: 1px !important;
    }

    .venreqsidebarBtn{
        border-right: 6px solid !important;
        height: 100% !important;
        color: #0dcaf0 !important;
        border-radius: 5px !important;
        width: 100% !important;
        display:flex !important;
        justify-content:center !important;
        align-items:center !important;
    }