.panels-title {
    font-size: 25px;
    font-weight: bold;
    padding-left: 25px;
}

.nimapLogo {
    height: 45px;
    margin-left: -12px;
}

.nav-main {
    display: grid;
    grid-template-columns: 1fr 3fr 2fr 2fr;
    padding: 7px 0;
}

.bg-light2 {
    padding-left: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.drawericon {
    padding-right: 15px;
    align-self: center;
    margin-top: 6px;
}

.text-div {
    padding-top: 5px;
    text-align: left;
}

.search-box {
    border-radius: 20px;
    border: 1px solid #dfeae6;
    padding: 0.8rem 1.2rem;
    color: black;
    width: 100%;
    outline: none;
    font-size: 18px;
}

.fa-bell-o {
    margin-right: 2rem;
}


.logoImg img,
.profilePhoto i,
.profilePhoto img {
    cursor: pointer;
}

.form-control:focus,
*:focus {
    box-shadow: none !important;
}

button.personal-information-modal-content-button {
    cursor: pointer;
    margin-left: 24px;
}

.MuiMenu-paper {
    max-height: calc(100% - 96px);
    -webkit-overflow-scrolling: touch;
    width: 200px;
    background-color: #f7fcff !important
}

.page-link:hover {
    z-index: 2;
    background-color: #4bcbf0 !important;
    color: #dee2e6 !important;
}

.navbar-class {
    padding-left: 24px !important;
    padding-top: 16px;
}

.name-text {
    padding-right: 23px;
    /* padding-top: 10px; */

}

.tr {
    border-bottom: 1px solid;
    height: 86px;
    overflow-x: inherit;

}

.border {
    border-bottom: 1px solid;
    display: grid;
    z-index: 0;
}

.user-name {
    padding-top: 10px;
    margin-left: 10px;
    text-align: left;
    font-size: 22px;
}

.profile-icon {
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.logo {
    font-size: 4rem !important;
    align-self: center;
    justify-self: end;
}

.personalTab {
    font-size: 18px !important;
}

.personal-txt {
    color: black;
}

.personal-txt:hover {
    color: black;
}
.menuBox{
    top: 40px !important;
    left:30px !important;
}

@media screen and (min-width:1025px) and (max-width:1200px) {
    .panels-title {
        font-size: 22px;
        font-weight: bold;
        padding-left: 25px;
    }

    .nav-main {
        display: grid;
        grid-template-columns: 1fr 2fr 2fr 2fr;
        padding: 7px 0;
    }

    .user-name {
        padding-top: 10px;
        margin-left: 10px;
        text-align: left;
        font-size: 18px;
    }
}


@media screen and (min-width:769px) and (max-width:1024px) {
    .nav-main {
        display: grid;
        grid-template-columns: 1fr 2fr 2fr 3fr;
        padding: 7px 0;
    }

    .user-name {
        padding-top: 10px;
        margin-left: 10px;
        text-align: left;
        font-size: 18px;
    }

    .panels-title {
        font-size: 22px;
        font-weight: bold;
        padding-left: 25px;
    }

    .profile-icon {
        display: grid;
        grid-template-columns: 1fr 2.5fr;
    }
    .menuBox {
        top: 40px !important;
        left: 9px !important;
    }
    .personalTab {
        font-size: 16px !important;
    }
}

@media screen and (min-width:481px) and (max-width:768px) {
    .nav-main {
        grid-template-columns: 1fr 6fr 1fr;
    }

    .bg-light2 {
        grid-column: 1/2;
        grid-row: 1/2;
    }

    .panels-title {
        font-size: 22px;
        font-weight: bold;
        padding-left: 25px;
    }

    .profile-icon {
        display: grid;
        grid-template-columns: 1fr;
        grid-column: 3/4;
        grid-row: 1/2;
    }

    .text-div {
        display: none;
    }

    .searchCol {
        grid-column: 2/3;
        grid-row: 1/2;
        display: grid;
        grid-template-columns: 1fr;
    }

    .search-box {
        width: 22rem;
        justify-self: end;
        padding: 0.2rem 0.8rem;
        font-size: 18px;
    }

    .logo {
        padding-left: 10px;
    }

    .user-name {
        display: none;
    }

    .nimapLogo {
        height: 40px;
        width: 15rem;
    }

    .table-title {
        font-size: 20px;
    }
    
    .MuiMenu-paper {
        width: 160px;
    }
    .MuiMenuItem-root {
        min-height: 32px !important;
    }
    .menuBox {
        top: 40px !important;
        left: 9px !important;
    }
    .personalTab {
        font-size: 14px !important;
    }
}

@media screen and (min-width:320px) and (max-width:480px) {
    .nav-main {
        grid-template-columns: 1fr 3fr 1fr;
    }

    .bg-light2 {
        padding-left: 12px;
    }

    .text-div {
        display: none;
    }

    .user-name {
        display: none;
    }

    .nimapLogo {
        height: 2.5rem;
        width: 12rem;
    }

    .search-box {
        padding: 0.2rem 0.8rem;
        font-size: 16px;
    }

    .profile-icon {
        grid-template-columns: 1fr;
    }

    .logo {
        font-size: 3rem !important;
    }

    .table-title {
        font-size: 20px;
    }

    .MuiMenu-paper {
        width: 160px;
    }

    .personalTab {
        font-size: 14px !important;
    }
    .menuBox {
        top: 40px !important;
        left: 12px !important;
    }
    .MuiMenuItem-root {
        min-height: 32px !important;
    }
}