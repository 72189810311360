::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
}

::-webkit-scrollbar-thumb {
  background-color: #61dafb;
  border-radius: 0.65rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.color-black {
  color: black;
}

.range1 {
  color: #f1270d;
}
.range2 {
  color: #ffa500;
}
.range3 {
  color: #179f17;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.toastsContainer {
  margin-top: 5%;
  right: 5%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.text {
  background: "white";
}

.text-left {
  padding-top: 20px;
  text-align: left;
}

.text-right {
  text-align: right;
}

.tex-center {
  text-align: center;
}

.sidebar-bg {
  background-color: #fff;
}

.form-control {
  box-shadow: 0px -1px 0px 0px rgb(0 0 0 / 37%);
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

HTML,
BODY,
#root {
  height: 100%;
}

/*Pagination css*/
.pageContainer {
  margin-top: 15px;
}

.selectLabel {
  font-size: 18px;
}

.paginationBtns {
  width: 100%;
  height: 30px;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
}

.paginationBtns a {
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid #0dcaf0;
  color: #0dcaf0;
  cursor: pointer;
  text-decoration: none;
}

.paginationBtns a:hover {
  color: white;
  background-color: #0dcaf0;
}

.paginationActive a {
  color: white;
  background-color: #0dcaf0;
}

.reset-text {
  margin-left: 0;
}

.AddFiles {
  background: none;
  border: none;
}
.AddFiles-button {
  background: none;
  border: none;
  position: absolute;
  right: 0%;
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .selectLabel {
    font-size: 14px;
  }

  .paginationBtns {
    font-size: 14px;
  }
  .toastsContainer {
    margin-top: 7% !important;
    margin-left: 45% !important;
    width: 34% !important;
    font-size: 16px;
  }
  .Toastify__toast {
    min-height: 0% !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 481px) {
  .selectLabel {
    display: none;
  }

  .rows-select {
    padding: 3.2px 0px;
    font-size: 14px;
  }

  .paginationBtns a {
    padding: 2px 8px;
  }
  .toastsContainer {
    margin-top: 17% !important;
    margin-left: 45% !important;
    width: 54% !important;
    height: 5px !important;
    font-size: 14px;
  }
  .Toastify__toast {
    min-height: 0% !important;
  }
}

.custom-file-label {
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.9;
}
.input-area-uploadinvoiceadmin{
  margin-top: 12px !important;
}
