.tableHead {
  display: table-header-group;
}

.search_container{
  width: 800px;
  margin: auto;
  margin-top: 70px;

}
.search_box , .close_icon{
  position: relative;
  padding: 12px;

}
.search_box
{
  width: 90%;
  border: 1px solid #ccc;
  outline: 0;
  border-radius: 18px;

}
.close_icon{
  border: 1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;


}

.close_icon::after{
  
  width: 20px;
  height: 20px;
  position: absolute;
  background-color:red;
  right:30px ;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 5px;
  font-weight: normal;
  font-size: 14px;
  box-shadow: 0 0 2px cyan;
  cursor: pointer;

}
.search_box:not(:valid) ~ .close_icon{
  display: none;
}
.minusicon {
  height: 40px;
  width: 40px;
  margin-bottom: 10px;
  margin-left: 5px;
}

.crossicon {
  height: 20px;
  width: 20px;
  border: none;
  margin-top: 10px;
  margin-left: 10px;
  /* border:1px solid black; */
}

.tableRow {
  display: table-row-group;
}



.cols1{
 
  margin: 0px;
  padding-right: 0%;
  
}

.cols2{
  
  
  padding-left: 0%;
 
}

.cols3{
  
  margin: 0px;
  padding-right: 0%;
}

.cols4{
  padding-left: 0%;
 
}

.filtertitle {
  font-size: 20px !important;
  text-align: left;
}

.userfilter-btns {
  text-align: end;
  padding-right: 20px;
}

.filter-btn {
  text-align: end;
  padding-right: 36px;
}

.overdue_high {
  color: red;
}

.overdue_low {
  color: #0eff15;
}

.date-input {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  padding: 0.7rem;
  border-radius: 5px;
  border: 1px solid #a2afb4;
}

.select-box {
  margin-top: 10px;
  border: 1px solid #a2afb4;
  border-radius: 10px;
  width: 100%;
  padding: 1rem;
  outline: none;
}

.modal-btn31 {
  background-color: #0dcaf0;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 100%;
  font-size: 20px;
}

.invfilter {
  position: fixed;
  top: 49%;
  left: 85%;
  width: 35rem;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px;
  padding: 2.5rem;
}

.backgroundColors {
  margin-top: 45px;
  background-color: #0dcaf0;
  color: white;
  border: none;
  height: 37px;
  width: 120px;
  font-size: 20px;
}

.filter-pop {
  padding: 20px;
}

.firstcol {
  text-align: right;
}

.secondcol {
  text-align: left;
}

.button {
  background-color: #0dcaf0;
  border-radius: 0.8rem;
  color: white;
  padding: 06px 04px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.bg-light {
  height: 74px;
}

.userClass {
  padding-right: 25px;
  padding-bottom: 2px;
}

.table-border {
  border: 1px solid rgba(13, 202, 240, 0.3);
  border-radius: 1.5rem;
  padding-left: 32px;
  padding-right: 32px;
}

.table -width {
  width: 97% !important;
}

.table-header-custom {
  width: fit-content;
  text-align: left;
}

.wrapper {
  text-align: center;
}

.subHeading {
  display: inline-block;
  width: "35%";
}

.width {
  width: 150px;
}

.bigpartist {
  width: 100% !important;
  float: right;
  transition: 0.5s;
  overflow-x: hidden !important;
}

.partist {
  /* width: calc(100vw - 225px) !important; */
  width: 81.5% !important;
  float: right;
  transition: 0.5s;
  overflow-x: hidden !important;
}

.makeStyles-drawerPaper-6 {
  width: 15%;
  overflow-x: clip;
}

.makeStyles-drawerPaper-10 {
  width: 15%;
  /* overflow-x: clip; */
}

.hr-modal {
  height: 6px !important;
  width: 39px;
}

.w-25 {
  width: 25%;
}

.jZkBQk .wrapper {
  padding: 0 !important;
}

.makeStyles-root-5 {
  display: flex;
  z-index: 0;
  padding-top: 24px;
}

.invoices-top-table {
  margin-bottom: -10px;
  padding-right: 30px;
  margin-top: 2rem;
}

.invoice-bottom-table22 {
  padding: 0px 20px;
}

.inr-symbol {
  margin-bottom: -4px;
  font-size: 17px;
}

h2#modal-modal-title {
  margin-left: -119%;
}

.sidebarsss {
  margin-top: 400px !important;
}

.actionbtn {
  visibility: hidden;
  cursor: initial;
}

.inputbox {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #b7c4c9;
}

.inv-label {
  text-align: left;
  display: block;
  font-size: 16px;
}
.inv-label1 {
  text-align: left;
  display: block;
  margin-top: 5px;
  font-size: 13px;
  color: #0dcaf0;
 
}
.inv-label3 {
  text-align: left;
  display: block;
  font-size: 13px;
  color: #0dcaf0;
 
}
.inv-label2 {
  margin-top: 6px;

  text-align: left;
  display: block;
  font-size: 13px;
  color:red;
 
}
.inv-label4 {
  margin-top: 18px;

  text-align: left;
  display: block;
  font-size: 13px;
  color:red;
 
}



.doc-label {
  text-align: left;
  display: block;
  margin-top: 10px;
  margin-bottom: 0;
}



.row_space {
  padding: 0 99px;
}
.row_space1 {
  padding: 0 99px;
}
.row_space2 {
  padding: 0 99px 15px;
}

.doctwo {
  margin-top: 39px !important;
}

.editlink {
  color: #0dcaf0;
}

.editlink:hover {
  color: #0dcaf0;
}

.invoice-form {
  margin-top: 14px;
  padding-bottom: 30px;
}

.registerPage {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.leftside {
  background-image: url("../images/register.png");
  background-repeat: no-repeat;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.rightside {
  padding: 3rem 5rem 0;
}

.register-img {
  height: 100vh;
  width: 100%;
}

.captcha-row {
  margin: 0.5em 0;
}

.form-col {
  padding: 5rem;
}

.reg-rows {
  padding-top: 10px;
}

.regbtn-rows {
  padding-top: 30px;
  justify-content: center;
}

.regsbmtbtn {
  background-color: #0dcaf0;
  color: white;
  border: none;
  padding: 12px 41px;
  font-size: 18px;
}

.regfield-col {
  text-align: left;
}

.reg-label {
  font-size: 16px;
  font-weight: 100;
  text-align:"left";
  width:"100%"
}

.reg-input {
  display: grid;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #b7c4c9;
}

.reg-input::placeholder {
  font-size: 0.5em;
}

.headrows {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .partist {
    width: 75% !important;
  }

  .userfilter-btns.col-lg-9 {
    margin-top: -107px;
  }

  .invfilter {
    position: fixed;
    top: 29rem;
    left: 57rem;
    width: 27rem;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 20px;
    padding: 2rem;
  }

  .filtertitle {
    font-size: 18px !important;
    text-align: left;
  }

  .date-input {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    padding: 0.3rem;
    border-radius: 5px;
    border: 1px solid #a2afb4;
  }

  .filterRow.row {
    display: flex;
    flex-wrap: nowrap;
  }

  .backgroundColors {
    margin-top: 45px;
    background-color: #0dcaf0;
    color: white;
    border: none;
    height: 37px;
    width: 129px;
    font-size: 18px;
  }

  .invoice-bottom-table22 {
    padding: 0px 5px;
  }

  .row_space {
    padding: 0 15px;
  }
  .row_space1 {
    padding: 0 15px;
  }
  .row_space2 {
    padding: 0 15px 5px;
  }

  .cols1{
    
    margin: 0px;
    padding-right: 0%;
  }
  
  .cols2{
    
    
    padding-left: 0%;
   
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .partist {
    width: 96.5% !important;
  }

  .invoice-bottom-table22 {
    padding: 0px 5px;
    margin-top: 1px;
  }

  .userfilter-btns.col-lg-9 {
    margin-top: -106px;
    margin-right: 0px;
  }

  .invfilter {
    position: fixed;
    top: 31rem;
    left: 27rem;
    width: 27rem;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 20px;
    padding: 2rem;
  }

  .filtertitle {
    font-size: 16px !important;
    text-align: left;
  }

  .backgroundColors {
    margin-top: 45px;
    background-color: #0dcaf0;
    color: white;
    border: none;
    height: 35px;
    width: 46px;
    font-size: 16px;
  }

  .row_space {
    padding: 0 34px;
  }
  .row_space1 {
    padding: 0 34px;
  }
  .row_space2 {
    padding: 0 34px 10px;
  }
  .cols1{
    
    margin: 0px;
    padding-right: 0%;
  }
  
  .cols2{
    
    
    padding-left: 0%;
   
  }
}

@media screen and (min-width: 481px) and (max-width: 575px) {
  .partist {
    width: 96.5% !important;
  }

  .invoice-bottom-table22 {
    padding: 0px 5px;
    margin-top: 1px;
  }

  .userfilter-btns.col-lg-9 {
    margin-top: -106px;
    margin-right: 0px;
  }

  .invfilter {
    position: fixed;
    top: 31rem;
    left: 27rem;
    width: 27rem;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 20px;
    padding: 2rem;
  }

  .filtertitle {
    font-size: 16px !important;
    text-align: left;
  }

  .backgroundColors {
    margin-top: 45px;
    background-color: #0dcaf0;
    color: white;
    border: none;
    height: 35px;
    width: 46px;
    font-size: 16px;
  }

  .row_space {
    padding: 0 34px;
  }
  .row_space1 {
    padding: 0 34px;
  }
  .row_space2 {
    padding: 0 34px 10px;
  }
  .cols1{
    
    margin: 0px;
    padding-right:0%;
    
  }
  
  .cols2{
    
    
    padding-left: 0%;
   
  }
  .crossicon {
    height: 20px;
    width: 20px;
    border: none;
    margin-top: 10px;
    margin-left: 10px;
  }
}

@media screen and (min-width: 320px) and (max-width: 481px) {
  .bigpartist {
    width: 99% !important;
  }

  .partist {
    width: 99% !important;
  }

  .invoice-bottom-table22 {
    padding: 0px 1px;
  }

  .inv-label {
    margin-top: 5px;
    font-size: 16px;
  }

  .inv-label1 {
    margin-top: 5px;
    font-size: 13px;
  color: #0dcaf0;
  }
  .inv-label3 {

    font-size: 13px;
  color: #0dcaf0;
  }
  .inv-label2 {
    margin-top: 10px;
    font-size: 13px;
  color: red;
  }
  .inv-label4 {
    margin-top: 18px;
    font-size: 13px;
  color: red;
  }

  .inputbox {
    padding: 2px;
    margin-top: 4px;
  }

  .row_space {
    padding: 0px 10px;
    display: grid;
  }
  .row_space1 {
    padding: 0px 10px;
    display: grid;
  }
  .row_space2 {
    padding: 0px 10px 2px;
    display: grid;
  }
  .cols1{
    
    margin: 0px;
    padding-right: 0%;
  }
  
  .cols2{
    
    
    padding-left: 0%;
   
  }
  .crossicon {
    height: 20px;
    width: 20px;
    border: none;
    margin-top: 15px;
    margin-left: 10px;
  }
}

.tooltip-desc{
  height: 170px !important;
overflow-y: hidden;
border-radius:5px;
font-size: 16px;
}

.tooltip-desc{
  overflow-y: scroll; /* Add the ability to scroll */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.tooltip-desc::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.tooltip-desc{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.vendorrow{
  margin-bottom: 4px !important;
}
.vendorprofilegstdoc{
  text-align: left !important;
/* border:1px solid black; */
  width: 100%;
}
.pandocument{
  text-align: left !important;
/* border:1px solid black; */
  width: 100%;
}
.tannumber-row{
  margin-top: 10px;
}
.tan-num{
  margin-top: 7px;
}

.error-text-vendor-form{
color:red !important;
font-size: 14px !important;
}

.leftmr{
  text-align: left;
}
.red{
  color:red;
}
.mandatory {
  color: red;
}

.venusername{
color:red !important;
font-size: 14px !important; 
}

.formadmincreateinv{
  /* border: 1px solid#0DCAF0 !important; */
  border-radius: 20px;
  margin-bottom: 2%;
  width:100%;
  background-color: #fff;
  box-shadow: 0 0 5px #999;
  margin-top: 20px;
}

.table-title{
  font-family: 'Geologica', sans-serif;
font-family: 'Work Sans', sans-serif;
}


.form_invoice_row{
  margin-top: 10px;
}
.form_invoice_row-bannkaccnum{
  margin-top: 19px;
}
.form_invoice_row-baseamount{
  margin-top: 24px;
}
.uploadinvcol{
  margin-top: 30px;
}
/* .widthss{
  padding: 0px 90px;
} */
.admincreinv-container{
  padding: 0px 90px;
}
.uploadinvoicetitile{
font-weight: 600;
}

.admin_create_invoice_bottomborder {
  background-color: #0dcaf0;
  border-radius:10px;
  color: white;
  border: none;
  height: 5px !important;
  margin: 0 142px 15px 0;
  width: 60px !important;
}
.form_row-support{
  margin-top: 14px;
}

.form_row-plus{
  margin-top:16px;
}

.mandatory {
  color: red;
}
.uploadinvoiceadmin{
  font-size: 15.5px;
}
.doc-label{
  font-size: 15.5px;
}
.crossdiv{

 display:flex;
 align-items: center;
 justify-content: center;

}

.input-area-uploadinvoiceadmin{
  margin-top: 0px !important;
  max-height: 11.9rem !important;
    min-height: 11.9rem!important;
}
.vendordescription{
  max-height: 11.9rem !important;
  min-height: 11.9rem!important;
}
.bnkaccinp{
  background-color:#D3D3D3 !important;
}
.ifscinp{
  background-color:#D3D3D3 !important;
}
.tdsinp{
  background-color:#D3D3D3 !important;
}
.accnosubtext{
  font-size: 13.2px;
  margin-left:7px;
}
.venreg-iframe{
  pointer-events: auto;
}